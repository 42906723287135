import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import React from 'react';
import { useTranslation } from 'react-i18next';

const Payments = ({ formStyle, adminData, setAdminData, setChanged }) => {
  const { t } = useTranslation();
  const agreementDetails = adminData.agreementDetails || {};
  const handleChange = (event, attr) => {
    setChanged(true);
    if (attr === 'startDate') {
      return setAdminData({
        ...adminData,
        agreementDetails: { ...agreementDetails, [attr]: event },
      });
    }
    if (event.target.type === 'number') {
      return setAdminData({
        ...adminData,
        agreementDetails: { ...agreementDetails, [attr]: +event.target.value },
      });
    }
    setAdminData({
      ...adminData,
      agreementDetails: { ...agreementDetails, [attr]: event.target.value },
    });
  };

  return (
    <div style={{ display: 'flex', marginTop: '20px', marginBottom: '20px' }}>
      <div style={{ width: '45%' }}>
        <div style={formStyle}>
          <FormControl sx={{ my: 1 }}>
            <FormLabel id="demo-row-radio-buttons-group-label">{t('Agreement')}</FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={agreementDetails.agreement}
              onChange={(e) => handleChange(e, 'agreement')}
            >
              <FormControlLabel value="monthly" control={<Radio />} label={t('Monthly')} />
              <FormControlLabel value="annually" control={<Radio />} label={t('Annually')} />
            </RadioGroup>
          </FormControl>
          <TextField
            margin="normal"
            label={t('Price')}
            variant="outlined"
            type="number"
            value={agreementDetails.price}
            onChange={(e) => handleChange(e, 'price')}
          />
          <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={'de'}>
            <DatePicker
              views={['year', 'month', 'day']}
              label={t('Start Date')}
              value={
                agreementDetails.startDate
                  ? agreementDetails.startDate instanceof Date
                    ? agreementDetails.startDate
                    : new Date(agreementDetails.startDate.seconds * 1000)
                  : null
              }
              onChange={(newValue) => handleChange(new Date(newValue), 'startDate')}
              renderInput={(params) => <TextField {...params} margin="normal" />}
            />
          </LocalizationProvider>
          <FormControl sx={{ my: 1 }}>
            <FormLabel id="demo-row-radio-buttons-group-label">{t('Payment Method')}</FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={agreementDetails.paymentMethod}
              onChange={(e) => handleChange(e, 'paymentMethod')}
            >
              <FormControlLabel value="advance" control={<Radio />} label={t('Advance Payment')} />
              <FormControlLabel value="post" control={<Radio />} label={t('Post Payment')} />
            </RadioGroup>
          </FormControl>
        </div>
      </div>
      <div style={{ width: '55%' }}>
        <DataGrid
          initialState={{
            sorting: {
              sortModel: [{ field: 'startDate', sort: 'desc' }],
            },
          }}
          rows={agreementDetails.pastAgreements || []}
          columns={[
            {
              field: 'agreement',
              headerName: t('Agreement'),
              flex: 1,
              valueFormatter: (params) => t(params.value),
            },
            {
              field: 'price',
              headerName: t('Price'),
              flex: 1,
              valueFormatter: (params) => `${params.value?.toFixed(2)} €`,
            },
            {
              field: 'startDate',
              headerName: t('Start Date'),
              flex: 1,
              valueFormatter: (params) =>
                new Date(params.value.seconds * 1000).toLocaleDateString('de-DE'),
            },

            {
              field: 'paymentMethod',
              headerName: t('Payment Method'),
              flex: 1,
              valueFormatter: (params) => t(params.value),
            },
            {
              field: 'updated',
              headerName: t('Added'),
              flex: 1,
              valueFormatter: (params) =>
                new Date(params.value.seconds * 1000).toLocaleString('de-DE'),
            },
          ]}
          getRowId={(row) => agreementDetails.pastAgreements?.indexOf(row)}
          pageSize={10}
          rowsPerPageOptions={[10]}
          disableSelectionOnClick
        />
      </div>
    </div>
  );
};

export default Payments;
