import * as React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { deleteJob, setBackdropVisible, setCurrentJob, setJobs } from '../../Redux/Actions';

import { DataGrid, deDE, GridToolbarQuickFilter } from '@mui/x-data-grid';
import Typography from '@mui/material/Typography';
import '../../App.css';
import ConstructionIcon from '@mui/icons-material/Construction';
import { Button, Chip, Icon, IconButton, LinearProgress } from '@mui/material';
import { Add, ChangeCircleSharp, DateRange, Delete } from '@mui/icons-material';
import { collection, query, onSnapshot, orderBy, where } from 'firebase/firestore';
import { fireStore } from '../../firebase/config';
import { useEffect } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import JobsRange from '../../components/Job/JobsRange/JobsRange';
import { format } from 'date-fns';

const chipStyle = {
  fontSize: '1.2rem',
  height: '40px',
  paddingLeft: '10px',
  paddingRight: '10px',
  marginBottom: '5px',
  marginRight: '10px',
};

function CustomToolbar(
  from,
  showDelete,
  setShowDelete,
  jobsFilter,
  setJobsFilter,
  setShowRangeSelector,
  dateRange
) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const jobsSearchActive = useSelector((state) => state.job.jobsSearchActive);

  return (
    <div className="Table-toolbar" style={{ padding: '4px' }}>
      {from === 'dashboard' ? null : (
        <>
          <Icon fontSize="large" className="Table-icon">
            <ConstructionIcon fontSize="30px" style={{ color: 'white' }} />
          </Icon>
          <div
            className="Table-icon-bg"
            style={{
              width: '116px',
              height: '116px',
              background: process.env.REACT_APP_PRIMARY_CLR_DARK,
            }}
          ></div>
        </>
      )}
      <Typography
        component="h1"
        variant="h6"
        color="#333333"
        // noWrap
        // sx={{ flexGrow: 1 }}
        // style={{ textTransform: "capitalize" }}
      >
        {from === 'dashboard' ? t('Today To Receive') : ''}
      </Typography>
      {from === 'dashboard' ? null : (
        <>
          <div
            style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
          >
            <Button
              size="small"
              style={{ fontSize: '16px', marginTop: '-10px', fontWeight: 'bold' }}
              onClick={() => setShowRangeSelector(true)}
              startIcon={<DateRange />}
            >
              {format(dateRange.startDate, 'dd.MM.yyyy')} -{format(dateRange.endDate, 'dd.MM.yyyy')}
            </Button>
            <div style={{ display: 'flex', alignItems: 'start' }}>
              <div>
                <Chip
                  label={'Alle'}
                  key={'x'}
                  clickable
                  sx={chipStyle}
                  onClick={() => setJobsFilter('All')}
                  color={jobsFilter === 'All' ? 'primary' : 'default'}
                />
              </div>
              <Chip
                label={t('Pending')}
                key={'y'}
                clickable
                sx={chipStyle}
                onClick={() => setJobsFilter('pending')}
                color={jobsFilter === 'pending' ? 'primary' : 'default'}
              />
              <Chip
                label={t('Completed')}
                key={'z'}
                clickable
                sx={chipStyle}
                onClick={() => setJobsFilter('completed')}
                color={jobsFilter === 'completed' ? 'primary' : 'default'}
              />
              <Chip
                label={t('Estimation')}
                key={'xy'}
                clickable
                sx={chipStyle}
                onClick={() => setJobsFilter('estimation')}
                color={jobsFilter === 'estimation' ? 'primary' : 'default'}
              />
            </div>
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {/* <GridToolbarColumnsButton /> */}

            <GridToolbarQuickFilter
              autoFocus={jobsSearchActive}
              variant="outlined"
              debounceMs={500}
              placeholder={t('Job No, Customer, Device...')}
              sx={{ width: '300px' }}
            />
            {/* <GridToolbarFilterButton sx={{ fontSize: "1rem", marginLeft: "15px" }} variant="outlined" />
        <GridToolbarExport sx={{ fontSize: "1rem", marginLeft: "15px" }} variant="outlined" /> */}
            <Button
              sx={{ fontSize: '1rem', marginLeft: '15px' }}
              startIcon={<Add />}
              variant="contained"
              onClick={() => dispatch(setBackdropVisible(true, 'job wizard', 'adding'))}
            >
              {t('Repair Job')}
            </Button>
            <IconButton
              fontSize={'large'}
              sx={{ marginLeft: '10px' }}
              onClick={() => setShowDelete(!showDelete)}
            >
              <Delete fontSize={'large'} color="primary" />
            </IconButton>
          </div>
        </>
      )}
    </div>
  );
}

const dateTimeOptions = {
  year: '2-digit',
  month: '2-digit',
  day: '2-digit',
  hour: '2-digit',
  minute: '2-digit',
  hour12: false,
  timeZone: 'Europe/Berlin',
};

export default function Jobs({ from }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const jobs = useSelector((state) => state.job.jobs);
  const [loading, setLoading] = useState(true);
  const [showDelete, setShowDelete] = useState(false);

  const [jobsFilter, setJobsFilter] = useState('pending');
  const [filteredJobs, setFilteredJobs] = useState(jobs);

  const [showRangeSelector, setShowRangeSelector] = useState(false);
  const thirtyDaysAgo = new Date();
  thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
  const [dateRange, setDateRange] = useState({
    startDate: thirtyDaysAgo,
    endDate: new Date(),
  });

  // console.log("job timestamp", new Date(1657384200 * 1000));
  // console.log("date compare", new Date() === new Date(1657384200 * 1000));
  // console.log("filteredJobs :>> ", filteredJobs);

  useEffect(() => {
    if (jobsFilter === 'All') {
      setFilteredJobs(jobs);
    } else {
      if (from === 'dashboard') {
        const today = new Date();
        setFilteredJobs(
          jobs.filter(
            (j) => new Date(j.returnDate.seconds * 1000).toDateString() === today.toDateString()
          )
        );
      } else {
        const filtered = jobs.filter((d) => d.status === jobsFilter);
        setFilteredJobs(filtered);
      }
    }

    return () => {};
  }, [jobsFilter, jobs]);

  useEffect(() => {
    let isMounted = true;
    const q = query(
      collection(fireStore, 'jobs'),
      where('timestamp', '>=', dateRange.startDate, 'timestamp', '<=', dateRange.endDate),
      orderBy('timestamp', 'desc')
    );
    if (isMounted) {
      setLoading(true);
      onSnapshot(q, (querySnapshot) => {
        console.log('querySnapshot :>> ', querySnapshot);
        let jobs = [];
        querySnapshot.forEach((doc) => {
          jobs.push({ ...doc.data(), id: doc.id });
        });
        dispatch(setJobs(jobs));
        setLoading(false);
      });
    }
    // TimeAgo.addDefaultLocale(de)
    return () => {
      isMounted = false;
      console.log('jobs unmounted');
    };
  }, [dateRange]);

  const handleDeleteJob = (e, params) => {
    e.preventDefault();
    e.stopPropagation();
    console.log('row :>> ', params);
    dispatch(deleteJob(params.id));
  };

  const columns = [
    { field: 'id', hide: true },
    {
      field: 'jobNumber',
      headerName: t('Job No.'),
      headerClassName: 'table-header',
      flex: 1,
    },
    {
      field: 'timestamp',
      headerName: t('Date'),
      type: 'dateTime',
      flex: 1,
      headerClassName: 'table-header',
      valueFormatter: (params) => {
        if (params.value == null) {
          return '';
        }

        const dateFormatted = new Date(params.value.seconds * 1000).toLocaleDateString(
          'de-DE',
          dateTimeOptions
        );
        return dateFormatted;
      },
      valueParser: (value) => Number(value) / 100,
    },

    {
      field: 'customerName',
      headerName: t('Customer'),
      headerClassName: 'table-header',
      flex: 2,
    },
    {
      field: 'deviceName',
      headerName: t('Device'),
      headerClassName: 'table-header',
      editable: true,
      flex: 2,
    },
    { field: 'deviceIMEI', hide: true },
    {
      field: 'status',
      headerName: t('Status'),
      headerClassName: 'table-header',
      flex: 2,
      renderCell: (params) => (
        <Chip
          variant={'outlined'}
          color={
            params.row.status === 'pending'
              ? 'warning'
              : params.row.status === 'estimation'
              ? 'primary'
              : 'success'
          }
          label={
            params.row.status === 'pending'
              ? t('Pending')
              : params.row.status === 'estimation'
              ? t('Estimation')
              : t('Completed')
          }
        />
      ),
    },
    {
      field: 'returnDate',
      headerName: t('Return Date'),
      type: 'dateTime',
      flex: 1,
      headerClassName: 'table-header',
      valueFormatter: (params) => {
        if (params.value == null) {
          return '';
        }

        const dateFormatted = new Date(params.value.seconds * 1000).toLocaleDateString(
          'de-DE',
          dateTimeOptions
        );
        return dateFormatted;
      },
      valueParser: (value) => Number(value) / 100,
    },
    {
      field: 'delete',
      headerName: t('Delete'),
      headerClassName: 'table-header',
      flex: 1,
      hide: !showDelete,
      renderCell: (params) => (
        <IconButton onClick={(e) => handleDeleteJob(e, params)}>
          <Delete color="error" />
        </IconButton>
      ),
    },
  ];

  const rows = filteredJobs.map((o, i) => ({
    id: o.id,
    jobNumber: o.jobNumber,
    timestamp: o.timestamp,
    customerName: o.customer.name,
    deviceName: o.device.name,
    deviceIMEI: o.device.serial,
    status: o.status,
    returnDate: new Date(o.returnDate.seconds * 1000).toLocaleDateString('de-DE', dateTimeOptions),
    ...o,
  }));

  const showJobDetails = (row) => {
    console.log('selected job', row.row);
    const job = {
      ...row.row,
    };
    delete job['customerName'];
    delete job['deviceName'];
    delete job['deviceIMEI'];

    dispatch(setCurrentJob(job));
    dispatch(setBackdropVisible(true, 'job details', 'viewing'));
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: from === 'dashboard' ? '100%' : '90%',
      }}
    >
      <div>
        <JobsRange
          setOrders={[]}
          dateRange={dateRange}
          setDateRange={setDateRange}
          showRangeSelector={showRangeSelector}
          setShowRangeSelector={setShowRangeSelector}
        />
      </div>
      <div style={{ flexGrow: 1, background: 'white' }}>
        <DataGrid
          initialState={{
            sorting: {
              sortModel: [
                {
                  field: from === 'dashboard' ? 'returnDate' : 'timestamp',
                  sort: from === 'dashboard' ? 'asc' : 'desc',
                },
              ],
            },
          }}
          editMode="row"
          density="comfortable"
          loading={loading}
          rows={rows}
          columns={columns}
          headerHeight={65}
          pageSize={20}
          onRowClick={(row) => showJobDetails(row)}
          components={{
            Toolbar: () =>
              CustomToolbar(
                from,
                showDelete,
                setShowDelete,
                jobsFilter,
                setJobsFilter,
                setShowRangeSelector,
                dateRange
              ),
            LoadingOverlay: LinearProgress,
          }}
          sx={{
            minHeight: from === 'dashboard' ? 'unset' : '86vh',
            padding: '10px',
            fontSize: '1.1rem',
            '& .MuiDataGrid-columnHeaderTitle': {
              fontWeight: 'bold',
            },
            '& .table-header': {
              marginTop: '10px',
            },
            '& .MuiDataGrid-row:hover': {
              cursor: 'pointer',
              // boxShadow: "0px 0px 2px 3px #80808030",
              // margin: "10px",
            },
          }}
          localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
          //   rowsPerPageOptions={[5]}
          //   checkboxSelection
          disableSelectionOnClick
        />
      </div>
    </div>
  );
}
