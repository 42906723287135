import React, { useState } from "react";
import {
  Button,
  CircularProgress,
  Divider,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";

import Chip from "@mui/material/Chip";
import _ from "lodash";
import { Add, Close, Done } from "@mui/icons-material";
import { useEffect } from "react";
import InputAdornment from "@mui/material/InputAdornment";
import { useDispatch, useSelector } from "react-redux";
import {
  addItems,
  addRepairServicesToDevice,
  setDiscount,
  setExtraItems,
  setRepairServices,
  setTotals,
} from "../../../Redux/Actions";
import { useTranslation } from "react-i18next";

const chipStyle = {
  fontSize: "1.2rem",
  height: "42px",
  paddingLeft: "10px",
  paddingRight: "10px",
  margin: "0 0 10px 10px",
};

const chipsContainer = {
  padding: "20px 5px 10px 5px",
  border: "1px solid lightgrey",
  borderRadius: "5px",
  display: "inline-flex",
  flexWrap: "wrap",
  justifyContent: "flex-start",
  width: "95%",
  marginRight: "10px",
};

function Estimation() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const services = useSelector((state) => state.job.currentJob.jobServices);
  const extraItems = useSelector((state) => state.job.currentJob.extraItems);
  const totals = useSelector((state) => state.job.currentJob.totals);
  const items = useSelector((state) => state.job.items);

  const currentDevice = useSelector((state) => state.job.currentJob.device);
  const deviceRepairServices = currentDevice.repairServices;
  const devicesLoading = useSelector((state) => state.job.devicesLoading);
  const itemsLoading = useSelector((state) => state.job.itemsLoading);

  const [addingNewService, setAddingNewService] = useState(false);
  const [newService, setNewService] = useState({
    name: "",
    price: 0,
  });
  const [addingNewItem, setAddingNewItem] = useState(false);
  const [newItem, setNewItem] = useState({
    name: "",
    price: 0,
    stock: 0,
  });

  const pf = window.$priceFormater;

  // console.log("services :>> ", services);

  const handleServiceInputs = (e, service, index, attribute) => {
    const newServices = services.map((s, i) => {
      if (i === index) {
        return {
          ...service,
          [attribute]: attribute === "name" ? e.target.value : e.target.valueAsNumber,
        };
      } else return s;
    });
    dispatch(setRepairServices(newServices));
  };

  const handleItemInputs = (e, item, index, attribute) => {
    const newItems = extraItems.map((s, i) => {
      if (i === index) {
        return {
          ...item,
          [attribute]: attribute === "name" ? e.target.value : e.target.valueAsNumber,
        };
      } else return s;
    });
    dispatch(setExtraItems(newItems));
  };

  const handleAddNewServiceDone = () => {
    dispatch(addRepairServicesToDevice(currentDevice.id, newService));
    setAddingNewService(false);
    setNewService({ name: "", price: 0 });
  };

  const handleAddNewItemDone = () => {
    dispatch(addItems([newItem], false));
    setAddingNewItem(false);
    setNewItem({ name: "", price: 0, stock: 0 });
  };

  useEffect(() => {
    let newSubtotal = 0;

    services.forEach((s) => {
      newSubtotal = newSubtotal + (isNaN(s.price) ? 0 : s.price * s.quantity);
    });

    if (extraItems.length > 0) {
      extraItems.forEach((i) => {
        newSubtotal = newSubtotal + (isNaN(i.price) ? 0 : i.price * i.quantity);
      });
    }

    dispatch(setTotals(newSubtotal, newSubtotal - Math.abs(totals.discount)));

    return () => {};
  }, [services, totals.discount, extraItems]);

  return (
    <div style={{ textAlign: "left" }}>
      <div
        style={{
          marginTop: "20px",
          marginBottom: "20px",
          display: "inline-flex",
          alignItems: "flex-start",
          width: "100%",
        }}
      >
        <div style={{ display: "flex", flexDirection: "column", width: "55%" }}>
          <Typography variant="overline" sx={{ color: "#00000099", fontSize: "1rem" }}>
            {t("Repair Services")}
          </Typography>

          <div style={chipsContainer}>
            {deviceRepairServices.map((s, i) => (
              <Chip
                label={s.name + " " + s.price + " €"}
                key={i}
                clickable
                sx={chipStyle}
                onClick={() =>
                  _.some(services, ["name", s.name])
                    ? null
                    : dispatch(
                        setRepairServices([
                          ...services,
                          {
                            name: s.name,
                            quantity: 1,
                            price: s.price,
                          },
                        ])
                      )
                }
                color={_.some(services, ["name", s.name]) ? "primary" : "default"}
              />
            ))}

            <div style={{ display: "flex", alignItems: "flex-end", marginLeft: "10px" }}>
              {addingNewService ? (
                <>
                  <TextField
                    label={t("Service Name")}
                    margin="normal"
                    variant="outlined"
                    sx={{ width: "60%", marginRight: "10px" }}
                    value={newService.name}
                    onChange={(e) => {
                      setNewService({ ...newService, name: e.target.value });
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => {
                              setAddingNewService(false);
                            }}
                          >
                            <Close />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <TextField
                    label={t("Price")}
                    margin="normal"
                    variant="outlined"
                    sx={{ width: "20%" }}
                    value={newService.price}
                    onChange={(e) => {
                      setNewService({ ...newService, price: e.target.valueAsNumber });
                    }}
                    type="number"
                  />
                </>
              ) : null}
              {devicesLoading ? (
                <CircularProgress sx={{ margin: "10px" }} />
              ) : (
                <Chip
                  label={addingNewService ? t("Done") : t("Add")}
                  key="99"
                  clickable
                  disabled={addingNewService ? (newService.name.length < 1 ? true : false) : false}
                  onClick={() =>
                    addingNewService ? handleAddNewServiceDone() : setAddingNewService(true)
                  }
                  sx={chipStyle}
                  icon={addingNewService ? <Done /> : <Add />}
                  variant={"outlined"}
                  color={addingNewService ? "success" : "primary"}
                />
              )}
            </div>
          </div>

          <Typography variant="overline" sx={{ color: "#00000099", fontSize: "1rem" }}>
            {t("Items")}
          </Typography>
          <div style={chipsContainer}>
            {items
              .slice(0)
              .reverse()
              .map((s, i) => (
                <Chip
                  label={s.name + " " + s.price + " €"}
                  key={i}
                  clickable
                  sx={chipStyle}
                  onClick={() =>
                    _.some(extraItems, ["name", s.name])
                      ? null
                      : dispatch(
                          setExtraItems([
                            ...extraItems,
                            {
                              name: s.name,
                              quantity: 1,
                              price: s.price,
                            },
                          ])
                        )
                  }
                  color={_.some(extraItems, ["name", s.name]) ? "primary" : "default"}
                />
              ))}
            <div style={{ display: "flex", alignItems: "flex-end", marginLeft: "10px" }}>
              {addingNewItem ? (
                <>
                  <TextField
                    label={t("Item Name")}
                    margin="normal"
                    variant="outlined"
                    sx={{ width: "60%", marginRight: "10px" }}
                    value={newItem.name}
                    onChange={(e) => {
                      setNewItem({ ...newItem, name: e.target.value });
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => {
                              setAddingNewItem(false);
                            }}
                          >
                            <Close />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <TextField
                    label={t("Price")}
                    margin="normal"
                    variant="outlined"
                    sx={{ width: "20%" }}
                    value={newItem.price}
                    onChange={(e) => {
                      setNewItem({ ...newItem, price: e.target.valueAsNumber });
                    }}
                    type="number"
                  />
                </>
              ) : null}
              {itemsLoading ? (
                <CircularProgress sx={{ margin: "10px" }} />
              ) : (
                <Chip
                  label={addingNewItem ? t("Done") : t("Add")}
                  key="99"
                  clickable
                  disabled={addingNewItem ? (newItem.name.length < 1 ? true : false) : false}
                  onClick={() => (addingNewItem ? handleAddNewItemDone() : setAddingNewItem(true))}
                  sx={chipStyle}
                  icon={addingNewItem ? <Done /> : <Add />}
                  variant={"outlined"}
                  color={addingNewItem ? "success" : "primary"}
                />
              )}
            </div>
          </div>
        </div>
        <div style={{ width: "45%", padding: "20px", borderRadius: "10px", background: "#fff8ee" }}>
          {services.length > 0 ? (
            <Typography variant="overline" sx={{ color: "#00000099", fontSize: "1rem" }}>
              {t("Services")}
            </Typography>
          ) : null}
          {services.map((s, i) => (
            <div style={{ display: "flex", justifyConent: "space-between" }} key={i}>
              <TextField
                variant="filled"
                label={t("Service")}
                margin="dense"
                sx={{ "& .MuiFilledInput-input": { fontSize: "1.2rem" }, width: "65%" }}
                value={s.name}
                onChange={(e) => handleServiceInputs(e, s, i, "name")}
              />
              <TextField
                variant="filled"
                label={t("Quantity")}
                sx={{ "& .MuiFilledInput-input": { fontSize: "1.2rem" }, width: "15%" }}
                type={"number"}
                margin="dense"
                value={s.quantity}
                onChange={(e) => handleServiceInputs(e, s, i, "quantity")}
              />
              <TextField
                variant="filled"
                sx={{ "& .MuiFilledInput-input": { fontSize: "1.2rem" }, width: "20%" }}
                label={t("Price")}
                margin="dense"
                type={"number"}
                value={s.price}
                onChange={(e) => handleServiceInputs(e, s, i, "price")}
              />
              <IconButton
                sx={{ width: "10%" }}
                onClick={() =>
                  dispatch(setRepairServices(services.filter((s, index) => index !== i)))
                }
              >
                <Close color="error" />
              </IconButton>
            </div>
          ))}
          <Button
            fullWidth
            onClick={() =>
              dispatch(
                setRepairServices([
                  ...services,
                  {
                    name: "",
                    quantity: 1,
                    price: 0,
                  },
                ])
              )
            }
            startIcon={<Add />}
          >
            {t("Add Custom Service")}
          </Button>
          <Divider />
          <div>
            <div style={{ marginTop: "20px", marginBottom: "20px" }}>
              {extraItems.length > 0 ? (
                <Typography variant="overline" sx={{ color: "#00000099", fontSize: "1rem" }}>
                  {t("Items")}
                </Typography>
              ) : null}
              {extraItems.map((s, i) => (
                <div style={{ display: "flex", justifyConent: "space-between" }} key={i}>
                  <TextField
                    variant="filled"
                    label={t("Item")}
                    margin="dense"
                    sx={{ "& .MuiFilledInput-input": { fontSize: "1.2rem" }, width: "65%" }}
                    value={s.name}
                    onChange={(e) => handleItemInputs(e, s, i, "name")}
                  />
                  <TextField
                    variant="filled"
                    label={t("Quantity")}
                    sx={{ "& .MuiFilledInput-input": { fontSize: "1.2rem" }, width: "15%" }}
                    type={"number"}
                    margin="dense"
                    value={s.quantity}
                    onChange={(e) => handleItemInputs(e, s, i, "quantity")}
                  />
                  <TextField
                    variant="filled"
                    sx={{ "& .MuiFilledInput-input": { fontSize: "1.2rem" }, width: "20%" }}
                    label={t("Price")}
                    margin="dense"
                    type={"number"}
                    value={s.price}
                    onChange={(e) => handleItemInputs(e, s, i, "price")}
                  />
                  <IconButton
                    sx={{ width: "10%" }}
                    onClick={() =>
                      dispatch(setExtraItems(extraItems.filter((s, index) => index !== i)))
                    }
                  >
                    <Close color="error" />
                  </IconButton>
                </div>
              ))}
              <Button
                fullWidth
                onClick={() =>
                  dispatch(
                    setExtraItems([
                      ...extraItems,
                      {
                        name: "",
                        quantity: 1,
                        price: 0,
                      },
                    ])
                  )
                }
                startIcon={<Add />}
              >
                {t("Add Custom Items")}
              </Button>
            </div>
          </div>
          <div style={{ textAlign: "end", width: "95%" }}>
            <TextField
              variant="filled"
              label={t("Discount")}
              type={"number"}
              sx={{ "& .MuiFilledInput-input": { fontSize: "1.2rem" }, width: "100px" }}
              margin="dense"
              value={totals.discount}
              onChange={(e) => dispatch(setDiscount(e.target.valueAsNumber))}
              InputProps={{ endAdornment: <InputAdornment position="end">€</InputAdornment> }}
            />
          </div>
          <Divider />
          <div
            style={{
              display: "flex",
              textAlign: "right",
              width: "100%",
              margin: "20px",
              color: "grey",
              fontSize: "1.2rem",
            }}
          >
            <div style={{ width: "75%", paddingRight: "20px" }}>
              <div>{t("Subtotal")}</div>
              <div>{t("Discount")}</div>
              <div>{t("Total")}</div>
            </div>
            <div style={{ width: "17%" }}>
              <div>{pf.format(totals.subtotal)}</div>
              <div>{pf.format(totals.discount)}</div>
              <div>{pf.format(totals.total)}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Estimation;
