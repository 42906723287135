import React, { useState, useEffect } from 'react';

import 'react-clock/dist/Clock.css';
import { collection, doc, getDocs, query, setDoc } from 'firebase/firestore';
import { fireStore } from '../../firebase/config';
import { DataGrid } from '@mui/x-data-grid';
import ShopDialog from './ShopDialog';

const dateTimeOptions = {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
  weekday: 'long',
  hour: '2-digit',
  minute: '2-digit',
  hour12: false,
  timeZone: 'Europe/Berlin',
};

export default function Dashboard() {
  const [shops, setShops] = useState([]);
  const [loading, setLoading] = useState(true);

  const [defaultDB, setDefaultDB] = useState({});
  console.log('defaultDB', defaultDB);
  const [selectedShop, setSelectedShop] = useState(null);
  console.log('selectedShop', selectedShop);

  useEffect(() => { 
    handleFetchShops();
  }, []);

  const handleFetchShops = () => {
    // fetch shops from firebase and set to state
    const q = query(collection(fireStore, 'shops'));
    getDocs(q).then((querySnapshot) => {
      let shopsDB = [];
      querySnapshot.forEach((d) => {
        shopsDB.push({ ...d.data(), id: d.id });
      });
      setShops(shopsDB);
      setLoading(false);
    });
  };

  const handleFetchSelectedShopDB = (e, shopId) => {
    e.stopPropagation();
    // get all device-types
    const q = query(collection(fireStore, `shops/${shopId}/device-types`));
    const deviceTypes = [];
    getDocs(q).then((querySnapshot) => {
      console.log('q', q);
      querySnapshot.forEach((d) => {
        deviceTypes.push({ ...d.data(), id: d.id });
      });
    });

    const q2 = query(collection(fireStore, `shops/${shopId}/brands`));
    const brands = [];
    getDocs(q2).then((querySnapshot) => {
      console.log('q', q);
      querySnapshot.forEach((d) => {
        brands.push({ ...d.data(), id: d.id });
      });
    });

    const q3 = query(collection(fireStore, `shops/${shopId}/devices`));
    const devices = [];
    getDocs(q3).then((querySnapshot) => {
      querySnapshot.forEach((d) => {
        devices.push({ ...d.data(), id: d.id });
      });
    });

    // const q4 = query(collection(fireStore, `shops/${shopId}/settings`));
    // const settings = [];
    // getDocs(q4).then((querySnapshot) => {
    //   querySnapshot.forEach((d) => {
    //     settings.push({ ...d.data(), id: d.id });
    //   });
    // });

    const q5 = query(collection(fireStore, `shops/${shopId}/memories`));
    const memories = [];
    getDocs(q5).then((querySnapshot) => {
      querySnapshot.forEach((d) => {
        memories.push({ ...d.data(), id: d.id });
      });
    });

    const q6 = query(collection(fireStore, `shops/${shopId}/accessories`));
    const accessories = [];
    getDocs(q6).then((querySnapshot) => {
      querySnapshot.forEach((d) => {
        accessories.push({ ...d.data(), id: d.id });
      });
    });

    setDefaultDB({
      deviceTypes: deviceTypes,
      brands: brands,
      devices: devices,
      // settings: settings,
      memories: memories,
      accessories: accessories,
    });
  };

  const handleFetchDefaultDB = (e, shopId) => {
    e.stopPropagation();
    // get all device-types
    const q = query(collection(fireStore, 'device-types'));
    const deviceTypes = [];
    getDocs(q).then((querySnapshot) => {
      console.log('q', q);
      querySnapshot.forEach((d) => {
        deviceTypes.push({ ...d.data(), id: d.id });
      });
    });

    // get all brands
    const q5 = query(collection(fireStore, 'brands'));
    const brands = [];
    getDocs(q5).then((querySnapshot) => {
      querySnapshot.forEach((d) => {
        brands.push({ ...d.data(), id: d.id });
      });
    });

    // get all devices
    const q2 = query(collection(fireStore, 'devices'));
    const devices = [];
    getDocs(q2).then((querySnapshot) => {
      querySnapshot.forEach((d) => {
        devices.push({ ...d.data(), id: d.id });
      });
    });

    const q3 = query(collection(fireStore, 'settings'));
    const settings = [];
    getDocs(q3).then((querySnapshot) => {
      querySnapshot.forEach((d) => {
        settings.push({ ...d.data(), id: d.id });
      });
    });

    const q4 = query(collection(fireStore, 'memories'));
    const memories = [];
    getDocs(q4).then((querySnapshot) => {
      querySnapshot.forEach((d) => {
        memories.push({ ...d.data(), id: d.id });
      });
    });

    const q6 = query(collection(fireStore, 'accessories'));
    const accessories = [];
    getDocs(q6).then((querySnapshot) => {
      querySnapshot.forEach((d) => {
        accessories.push({ ...d.data(), id: d.id });
      });
    });

    setDefaultDB({
      deviceTypes: deviceTypes,
      brands: brands,
      devices: devices,
      settings: settings,
      memories: memories,
      accessories: accessories,
    });
  };

  const handlePasteDB = async (e, shopId) => {
    // get shopref with shopId
    e.stopPropagation();
    console.log('shopId :>> ', shopId);
    const shopRef = doc(fireStore, 'shops', shopId);
    // create collection for shop
    const deviceTypesRef = collection(shopRef, 'device-types');
    // create collection for shop
    const brandsRef = collection(shopRef, 'brands');
    // create collection for shop
    const devicesRef = collection(shopRef, 'devices');
    // create collection for shop
    // const settingsRef = collection(shopRef, 'settings');
    // create collection for shop
    const memoriesRef = collection(shopRef, 'memories');
    // create collection for shop
    const accessoriesRef = collection(shopRef, 'accessories');

    // // add device-types to shop
    const promises = defaultDB.deviceTypes.map(async (data, i) => {
      try {
        // Create a reference to the subcollection document with the custom ID
        const customId = data.id;
        console.log('customId :>> ', customId);
        const subCollectionDocRef = doc(deviceTypesRef, customId);

        const { id, ...dataWithoutId } = data;
        console.log('dataWithoutId :>> ', dataWithoutId);

        // Set the data for the subcollection document
        await setDoc(subCollectionDocRef, dataWithoutId);
        console.log(
          `Added device-types subcollection document: ${i + 1}/${defaultDB.deviceTypes.length}`
        );
      } catch (error) {
        console.error('Error adding device-types subcollection document: ', error);
      }
    });

    // Wait for all promises to complete
    await Promise.all(promises).then(() => {
      console.log('device-types completed :>> ');
    });

    // // add brands to shop
    const brandsPromises = defaultDB.brands.map(async (brand, i) => {
      try {
        const brandId = brand.id;
        const brandDocRef = doc(brandsRef, brandId);
        const { id, ...brandWithoutId } = brand;

        await setDoc(brandDocRef, brandWithoutId);
        console.log(`Added brand subcollection document: ${i + 1}/${defaultDB.brands.length}`);
      } catch (error) {
        console.error('Error adding brand subcollection document: ', error);
      }
    });

    // Wait for all promises to complete
    await Promise.all(brandsPromises).then(() => {
      console.log('brands completed :>> ');
    });

    // // add devices to shop
    const devicesPromises = defaultDB.devices.map(async (device, i) => {
      try {
        const deviceId = device.id;
        const deviceDocRef = doc(devicesRef, deviceId);
        const { id, ...deviceWithoutId } = device;

        await setDoc(deviceDocRef, deviceWithoutId);
        console.log(`Added device subcollection document: ${i + 1}/${defaultDB.devices.length}`);
      } catch (error) {
        console.error('Error adding device subcollection document: ', error);
      }
    });

    // Wait for all promises to complete
    await Promise.all(devicesPromises).then(() => {
      console.log('devices completed :>> ');
    });

    // // add settings to shop
    // const settingsPromises = defaultDB.settings.map(async (setting, i) => {
    //   try {
    //     const settingId = setting.id;
    //     const settingDocRef = doc(settingsRef, settingId);
    //     const { id, ...settingWithoutId } = setting;

    //     await setDoc(settingDocRef, settingWithoutId);
    //     console.log(`Added setting subcollection document: ${i + 1}/${defaultDB.settings.length}`);
    //   } catch (error) {
    //     console.error('Error adding setting subcollection document: ', error);
    //   }
    // });

    // Wait for all promises to complete
    // await Promise.all(settingsPromises).then(() => {
    //   console.log('settings completed :>> ');
    // });

    // add memories to shop
    const memoriesPromises = defaultDB.memories.map(async (memory, i) => {
      try {
        const memoryId = memory.id;
        const memoryDocRef = doc(memoriesRef, memoryId);
        const { id, ...memoryWithoutId } = memory;

        await setDoc(memoryDocRef, memoryWithoutId);
        console.log(`Added memory subcollection document: ${i + 1}/${defaultDB.memories.length}`);
      } catch (error) {
        console.error('Error adding memory subcollection document: ', error);
      }
    });

    // Wait for all promises to complete
    await Promise.all(memoriesPromises).then(() => {
      console.log('memories completed :>> ');
    });

    // add accessories to shop
    const accessoriesPromises = defaultDB.accessories.map(async (accessory, i) => {
      try {
        const accessoryId = accessory.id;
        const accessoryDocRef = doc(accessoriesRef, accessoryId);
        const { id, ...accessoryWithoutId } = accessory;

        await setDoc(accessoryDocRef, accessoryWithoutId);
        console.log(
          `Added accessory subcollection document: ${i + 1}/${defaultDB.accessories.length}`
        );
      } catch (error) {
        console.error('Error adding accessory subcollection document: ', error);
      }
    });

    // Wait for all promises to complete
    await Promise.all(accessoriesPromises).then(() => {
      console.log('accessories completed :>> ');
    });

    console.log('completed :>> ');
  };

  const handleRowClick = (params) => {
    console.log('params :>> ', params);
    setSelectedShop(params.row);
  };
  const handleCellClick = (params) => {
    console.log('params :>> ', params);
    if (params.field !== 'url') {
      setSelectedShop(params.row);
    }
  };

  return (
    <div style={{ display: 'flex', height: '100%' }}>
      <ShopDialog
        open={selectedShop !== null}
        selectedShop={selectedShop}
        setSelectedShop={setSelectedShop}
      />
      <div style={{ flexGrow: 1, background: 'white' }}>
        <DataGrid
          rows={shops}
          columns={[
            { field: 'id', headerName: 'ID', flex: 1 },
            { field: 'name', headerName: 'Name', flex: 1 },
            {
              field: 'url',
              headerName: 'URL',
              flex: 1,
              renderCell: (params) => {
                return (
                  <a href={params.value} target="_blank" rel="noopener noreferrer">
                    {params.value}
                  </a>
                );
              },
            },
            {
              field: 'created',
              headerName: 'Created',
              flex: 1,
              valueFormatter: (params) => {
                // convert firebase timestamp to date
                const date = new Date(params.value.seconds * 1000);
                return date.toLocaleDateString('en-US', dateTimeOptions);
              },
            },
            // {
            //   field: 'actions',
            //   headerName: 'Actions',
            //   flex: 1,
            //   renderCell: (params) => {
            //     return (
            //       <div>
            //         <button
            //           onClick={(e) => {
            //             // handleFetchDefaultDB(e, params.row.id);
            //             handleFetchSelectedShopDB(e, params.row.id);
            //           }}
            //           disabled={loading || defaultDB.devices !== undefined}
            //         >
            //           Fetch default DB
            //         </button>
            //         <button
            //           disabled
            //           onClick={(e) => {
            //             handlePasteDB(e, params.row.id);
            //           }}
            //         >
            //           Paste default DB
            //         </button>
            //       </div>
            //     );
            //   },
            // },
          ]}
          pageSize={20}
          rowsPerPageOptions={[20]}
          disableSelectionOnClick
          // onRowClick={handleRowClick}
          onCellClick={handleCellClick}
        />
      </div>
    </div>
  );
}
