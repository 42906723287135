import { Timestamp, collection, doc, getDocs, limit, query, updateDoc } from 'firebase/firestore';
import { useRef, useState } from 'react';
import { fireStore } from '../../firebase/config';
import { useEffect } from 'react';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { Box, Button, IconButton, InputAdornment, LinearProgress, TextField } from '@mui/material';
import { Cancel } from '@mui/icons-material';

const Prices = ({ selectedShop }) => {
  //   console.log('selectedShop', selectedShop);
  const [allDevices, setAllDevices] = useState([]);
  const [allServices, setAllServices] = useState([]);
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [loading, setLoading] = useState(true);

  const [priceAddon, setPriceAddon] = useState(0);
  const [newPrice, setNewPrice] = useState('');

  const [selectedRows, setSelectedRows] = useState([]);

  const apiRef = useRef();

  console.log('selectedRows', selectedRows);

  const cols = [
    { field: 'model', headerName: 'Model', flex: 4 },
    {
      field: 'repairService',
      headerName: 'Repair Service',
      valueGetter: (params) => {
        return params.row.repairService.name;
      },
      flex: 3,
    },
    {
      field: 'price',
      headerName: 'Price',
      flex: 2,
      type: 'number',
      valueGetter: (params) => {
        return params.row.repairService.price?.toFixed(2);
      },
      valueFormatter: (params) => {
        // add comma instead of decimal point
        return params.value?.toString().replace('.', ',');
      },
    },
    {
      field: 'newPrice',
      headerName: 'New Price',
      flex: 2,
      valueGetter: (params) => {
        if (params.row.repairService.price === 0) return 0;
        else if (selectedRows.includes(params.id))
          return (+params.row.repairService.price + priceAddon)?.toFixed(2);
        return (+params.row.repairService.price)?.toFixed(2);
      },
      valueFormatter: (params) => {
        // add comma instead of decimal point
        return params.value?.toString().replace('.', ',');
      },
    },
  ];

  useEffect(() => {
    setColumns(cols);
  }, [priceAddon, selectedRows]);

  //   console.log('allServices', allServices);

  useEffect(() => {
    fetchShopDevices();
  }, []);

  const fetchShopDevices = async () => {
    const q2 = query(
      // get first 100 devices
      collection(fireStore, `shops/${selectedShop.id}/devices`)
      // limit(50)
    );
    const devices = [];
    getDocs(q2).then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        devices.push({ ...doc.data(), id: doc.id });
      });
      setAllDevices(devices);
      const flattenedDevices = [];

      devices.forEach((device) => {
        const repairServices = device.repairServices;
        repairServices.forEach((r, idx) => {
          flattenedDevices.push({
            ...device,
            id: device.id + '-' + idx,
            repairService: r,
            repairServices: undefined,
          });
        });
      });

      setAllServices(flattenedDevices);
      setRows(flattenedDevices);
      setLoading(false);
    });
  };

  const handleRowClick = (params, event) => {
    console.log('params', params.getValue(params.id, 'newPrice'));
    console.log('event', event);
  };

  const handleShowSelectedRows = () => {
    const filteredRows = allServices.filter((row) => selectedRows.includes(row.id));
    // console.log('filteredRows', filteredRows);
    setRows(filteredRows);
  };

  const handleSaveNewPrices = () => {
    setLoading(true);
    const updatedServices = allServices.filter(
      (row) => selectedRows.includes(row.id) && row.repairService.price !== 0
    );
    console.log('updatedServices', updatedServices);
    let updatedDevices = [];
    updatedServices.forEach((u) => {
      const device = allDevices.find((d) => d.id === u.id.split('-')[0]);
      const idx = device.repairServices.findIndex((r) => r.name === u.repairService.name);
      device.repairServices[idx].price = +u.repairService.price + priceAddon;
      updatedDevices.push(device);
    });

    console.log('updatedDevices', updatedDevices);

    // update devices
    let promises = [];
    updatedDevices.forEach((d) => {
      const docRef = doc(fireStore, `shops/${selectedShop.id}/devices`, d.id);
      promises.push(
        updateDoc(docRef, { repairServices: d.repairServices, updated: Timestamp.now() })
      );
    });

    Promise.all(promises).then(() => {
      setLoading(false);
      setPriceAddon(0);
      setNewPrice('');
      fetchShopDevices();
    });

    // update services
  };

  const handlePriceInput = (e) => {
    // only accept numbers, decimal and minus sign and empty string
    const regex = /^-?\d*\.?\d*$/;
    if (regex.test(e.target.value)) {
      const number = +e.target.value;
      setPriceAddon(number);
      setNewPrice(e.target.value);
    }
  };

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <div style={{ display: 'flex', gap: 4 }}>
          <Button
            onClick={() => setRows(allServices)}
            variant={allServices.length === rows.length ? 'outlined' : 'standard'}
          >
            Show All ({allServices.length})
          </Button>
          <Button
            variant={selectedRows.length === rows.length ? 'outlined' : 'standard'}
            onClick={handleShowSelectedRows}
          >
            Show selected ({selectedRows.length})
          </Button>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', gap: 16 }}>
          <TextField
            label="Add New Price"
            size="small"
            variant="outlined"
            sx={{ my: 2 }}
            value={newPrice}
            disabled={selectedRows.length === 0}
            onChange={handlePriceInput}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    edge="end"
                    onClick={() => {
                      setPriceAddon(0);
                      setNewPrice('');
                    }}
                  >
                    <Cancel />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Button
            variant="contained"
            disabled={priceAddon === 0 || selectedRows.length === 0}
            onClick={handleSaveNewPrices}
          >
            Save New Prices
          </Button>
        </div>
      </div>
      <Box sx={{ height: 750, width: '100%' }}>
        {loading && <LinearProgress />}
        <DataGrid
          rows={rows}
          columns={columns}
          rowsPerPageOptions={[50, 20, 100, 500, 1000]}
          onRowClick={handleRowClick}
          disableSelectionOnClick
          checkboxSelection
          onSelectionModelChange={(newSelectionModel) => {
            setSelectedRows(newSelectionModel);
          }}
          selectionModel={selectedRows}
          components={{
            Toolbar: GridToolbar,
          }}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
          getCellClassName={(params) => {
            if (params.field === 'newPrice' && priceAddon !== 0 && params.value !== 0) {
              if (selectedRows.includes(params.id)) return 'cell-price-changed';
            }
            return '';
          }}
          sx={{
            '& .MuiDataGrid-columnHeaders': {
              backgroundColor: '#f5f5f5',
            },
            '& .MuiDataGrid-columnHeaderTitle': {
              fontWeight: 'bold',
            },
          }}
        />
      </Box>
    </div>
  );
};

export default Prices;
