import * as React from "react";

import { useDispatch, useSelector } from "react-redux";

import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { login } from "../../Redux/Actions";
import LockIcon from "@mui/icons-material/Lock";
import { useTranslation } from "react-i18next";

export default function LoginScreen() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");

  const helperText = useSelector((state) => state.user.error);

  const handleSubmit = (event) => {
    // setLoading(true);
    event.preventDefault();

    dispatch(login(email, password));

    // login(email, password)
    //   .then((res) => {
    //     console.log("login res :>> ", res);
    //     dispatch(verifySuccess());
    //     dispatch(setCurrentUser(res.user));
    //     setLoading(false);
    //   })
    //   .catch((err) => {
    //     setError(true);
    //     dispatch(verifyFailed);
    //     setHelperText("Incorrect email or password");
    //     setLoading(false);
    //   });
  };

  return (
    <div>
      <div className="login-background"></div>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 5,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ bgcolor: "secondary.main", width: "6rem", height: "6rem" }}>
            <LockIcon sx={{ fontSize: "4rem" }} />
          </Avatar>
          <h1 style={{ fontFamily: "Nunito", color: "white", zIndex: "7" }}>{t("Welcome")}</h1>
          <Box
            className="login-form"
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              autoComplete="email"
              autoFocus
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label={t("Password")}
              type="password"
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              helperText={helperText ? helperText.code : null}
            />

            <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
              {t("Sign In")}
            </Button>
          </Box>
        </Box>
      </Container>
    </div>
  );
}
