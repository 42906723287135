import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import CompanyProfile from '../../components/Profile/CompanyProfile';
import { doc, getDoc } from 'firebase/firestore';
import { fireStore } from '../../firebase/config';
import { useDispatch } from 'react-redux';
import { setSettings } from '../../Redux/Actions';
import { LinearProgress } from '@mui/material';
import { useEffect } from 'react';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ShopDialog({ open, setSelectedShop, selectedShop }) {
  const dispatch = useDispatch();

  const [adminPin, setAdminPin] = React.useState('');
  const [adminData, setAdminData] = React.useState({});
  const [orignalAgreementDetails, setOrignalAgreementDetails] = React.useState({});
  const [agreementDetailsChanged, setAgreementDetailsChanged] = React.useState(false);

  console.log('adminData :>> ', adminData);
  console.log('agreementDetailsChanged :>> ', agreementDetailsChanged);
  console.log('orignalAgreementDetails :>> ', orignalAgreementDetails);

  const [loading, setLoading] = React.useState(true);

  const checkAgreementDetailsChanged = () => {
    if (adminData.agreementDetails) {
      if (orignalAgreementDetails?.agreement === undefined) {
        if (
          adminData.agreementDetails.agreement !== '' ||
          adminData.agreementDetails.price !== 0 ||
          adminData.agreementDetails.startDate !== null ||
          adminData.agreementDetails.paymentMethod !== ''
        ) {
          setAgreementDetailsChanged(true);
        } else {
          setAgreementDetailsChanged(false);
        }
      } else {
        if (
          adminData.agreementDetails.agreement !== orignalAgreementDetails.agreement ||
          adminData.agreementDetails.price !== orignalAgreementDetails.price ||
          // compare firebase timestamp
          adminData.agreementDetails.startDate.seconds !==
            orignalAgreementDetails.startDate.seconds ||
          adminData.agreementDetails.paymentMethod !== orignalAgreementDetails.paymentMethod
        ) {
          setAgreementDetailsChanged(true);
        } else {
          setAgreementDetailsChanged(false);
        }
      }
    }
  };

  useEffect(() => {
    checkAgreementDetailsChanged();
  }, [adminData, orignalAgreementDetails]);

  React.useEffect(() => {
    if (selectedShop) {
      fetchShopSettings();
    }
  }, [selectedShop]);

  const handleClose = () => {
    setSelectedShop(null);
    setAdminData({});
    setAdminPin('');
    setOrignalAgreementDetails({});
    setLoading(true);
  };

  const fetchShopSettings = () => {
    // fetch shop settings
    const docRef = doc(fireStore, `shops/${selectedShop.id}/settings`, 'y1ap63Thh2ywGAxjvexM');
    getDoc(docRef).then((response) => {
      dispatch(setSettings(response.data(), response.id));
      setLoading(false);
    });
    const docRef2 = doc(fireStore, `shops/${selectedShop.id}/admins`, 'shop-admin');
    getDoc(docRef2).then((response) => {
      // check if doc exists
      if (response.exists()) {
        setAdminData(response.data());
        setAdminPin(response.data().pin);
        setOrignalAgreementDetails(response.data().agreementDetails);
      } else {
        setAdminData({});
        setAdminPin('');
        setOrignalAgreementDetails({});
      }
    });
  };

  return (
    <div>
      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {selectedShop?.name}
            </Typography>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        {loading ? (
          <LinearProgress />
        ) : (
          <CompanyProfile
            selectedShop={selectedShop}
            adminPin={adminPin}
            setAdminPin={setAdminPin}
            adminData={adminData}
            setAdminData={setAdminData}
            agreementDetailsChanged={agreementDetailsChanged}
            setAgreementDetailsChanged={setAgreementDetailsChanged}
            fetchShopSettings={fetchShopSettings}
          />
        )}
      </Dialog>
    </div>
  );
}
