
export const setBackdropVisible = (visible, component, currentAction) => {
  return {
    type: "SET_BACKDROP_VISIBLE",
    visible,
    component,
    currentAction,
  };
};

export const setSelectedJob = (job) => {
  return {
    type: "SET_SELECTED_JOB",
    job,
  };
};

export const setDeviceType = (dType) => {
  return {
    type: "SET_DEVICE_TYPE",
    dType,
  };
};

export const setDeviceModel = (dModel) => {
  return {
    type: "SET_DEVICE_MODEL",
    dModel,
  };
};

export const setRepairServices = (rServices) => {
  return {
    type: "SET_REPAIR_SERVICES",
    rServices,
  };
};

export const setExtraItems = (eItems) => {
  return {
    type: "SET_EXTRA_ITEMS",
    eItems,
  };
};

export const setDiscount = (discount) => {
  return {
    type: "SET_DISCOUNT",
    discount,
  };
};
export const setTotals = (subtotal, total) => {
  return {
    type: "SET_TOTALS",
    subtotal,
    total,
  };
};

export const setDeviceAttributes = (attribute, value) => {
  return {
    type: "SET_DEVICE_ATTRIBUTES",
    attribute,
    value,
  };
};

export const setCustomerDetails = (attribute, value) => {
  return {
    type: "SET_CUSTOMER_DETAILS",
    attribute,
    value,
  };
};

export const setReturnDate = (date) => {
  return {
    type: "SET_RETURN_DATE",
    date,
  };
};
export const setDifferentNameInputs = (attribute, value) => {
  return {
    type: "SET_DIFFERENT_NAME_INPUTS",
    attribute,
    value,
  };
};

export const setCurrentJob = (job) => {
  return {
    type: "SET_CURRENT_JOB",
    job,
  };
};

export const setJobNumber = (num) => {
  return {
    type: "SET_JOB_NUMBER",
    num,
  };
};
export const addJob = (job) => {
  return {
    type: "ADD_JOB",
    job,
  };
};

export const setEditMode = () => {
  return {
    type: "SET_EDIT_MODE",
  };
};

export const setJobs = (jobs) => {
  return {
    type: "SET_JOBS",
    jobs,
  };
};

export const setDeviceToEdit = (device) => {
  return {
    type: "SET_DEVICE_TO_EDIT",
    device,
  };
};

export const setModelSearchInput = (value) => {
  return {
    type: "SET_MODEL_SEARCH_INPUT",
    value,
  };
};

export const setJobsSearchActive = (toggle) => {
  return {
    type: "SET_JOBS_SEARCH_ACTIVE",
    toggle,
  };
};

export const setAutoCustomerDetails = (details) => {
  return {
    type: "SET_AUTO_CUSTOMER_DETAILS",
    details,
  };
};

export const setCustomerSignatures = (data) => {
  return {
    type: "SET_CUSTOMER_SIGNATURES",
    data,
  };
};
