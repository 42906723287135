import { fireStore } from '../../firebase/config';
import {
  collection,
  addDoc,
  Timestamp,
  getDoc,
  getDocs,
  query,
  orderBy,
  arrayUnion,
  arrayRemove,
  where,
  startAt,
  limit,
} from 'firebase/firestore';
import { doc, updateDoc, deleteDoc } from 'firebase/firestore';
import { setBackdropVisible, setDeviceType } from './job';

export const addJobCompleted = () => {
  return {
    type: 'ADD_JOB_COMPLETED',
  };
};

export const addJob = (job, estimationOnly) => {
  return (dispatch) => {
    dispatch(setBackdropVisible(false, '', ''));
    addDoc(collection(fireStore, 'jobs'), {
      timestamp: Timestamp.now(),
      status: estimationOnly ? 'estimation' : 'pending',
      ...job,
    }).then((response) => {
      dispatch(addJobCompleted());
      // window.history.pushState("", "", "/jobs");
      console.log('job added', response);
      addDoc(collection(fireStore, 'customers'), {
        created: Timestamp.now(),
        jobId: response.id,
        ...job.customer,
      }).then((res) => console.log('customer added :>> ', res));
    });
  };
};

export const updateJobStatusStart = () => {
  return {
    type: 'UPDATE_JOB_STATUS_START',
  };
};

export const updateCurrentJobStatus = (status) => {
  return {
    type: 'UPDATE_CURRENT_JOB_STATUS',
    status,
  };
};

export const updateJobStatus = (id, status) => {
  return (dispatch) => {
    dispatch(updateJobStatusStart());
    const docRef = doc(fireStore, 'jobs', id);

    updateDoc(docRef, { updated: Timestamp.now(), status: status }).then(() => {
      dispatch(updateCurrentJobStatus(status));
      console.log('Job status updated');
    });
  };
};

export const updateCurrentJobTechnician = (technician) => {
  return {
    type: 'UPDATE_CURRENT_JOB_TECHNICIAN',
    technician,
  };
};

export const updateJobTechnician = (id, technician) => {
  return (dispatch) => {
    dispatch(updateJobStatusStart());
    const docRef = doc(fireStore, 'jobs', id);

    updateDoc(docRef, { updated: Timestamp.now(), technician: technician }).then(() => {
      dispatch(updateCurrentJobTechnician(technician));
      console.log('Job technician updated');
    });
  };
};

export const updateJob = (job) => {
  return (dispatch) => {
    console.log('job.id :>> ', job.id);
    dispatch(setBackdropVisible(false, '', ''));
    const docRef = doc(fireStore, 'jobs', job.id);

    updateDoc(docRef, { updated: Timestamp.now(), ...job }).then(() => {
      console.log('Document updated');
      const q = query(collection(fireStore, 'customers'), where('jobId', '==', job.id));
      getDocs(q).then((docs) => {
        docs.forEach((doc) => {
          updateDoc(doc.ref, { updated: Timestamp.now(), ...job.customer }).then(() =>
            console.log('Customer Updated')
          );
        });
      });
    });
  };
};

export const deleteJob = (jobId) => {
  return (dispatch) => {
    const docRef = doc(fireStore, 'jobs', jobId);

    deleteDoc(docRef, jobId).then(() => console.log('Document Deleted'));
  };
};

export const setSettings = (settings, id) => {
  return {
    type: 'SET_SETTINGS',
    settings,
    id,
  };
};

export const getSettings = (settingId) => {
  return (dispatch) => {
    const docRef = doc(fireStore, 'settings', settingId);
    getDoc(docRef).then((response) => {
      dispatch(setSettings(response.data(), response.id));
    });
  };
};

export const startSaving = () => {
  return {
    type: 'START_SAVING',
  };
};

export const doneSaving = () => {
  return {
    type: 'DONE_SAVING',
  };
};

export const saveSettings = (
  settings,
  selectedShop,
  adminPin,
  adminData,
  agreementDetailsChanged,
  fetchShopSettings
) => {
  return (dispatch) => {
    dispatch(startSaving());
    const docRef = doc(fireStore, `shops/${selectedShop.id}/settings`, settings.settingsId);
    updateDoc(docRef, {
      updated: Timestamp.now(),
      ...settings,
    }).then((response) => {
      const docRef2 = doc(fireStore, `shops/${selectedShop.id}/admins`, 'shop-admin');
      const agreementDetails = adminData.agreementDetails || {};
      updateDoc(docRef2, {
        agreementDetails: {
          ...agreementDetails,
          pastAgreements: agreementDetailsChanged
            ? [
                ...(agreementDetails.pastAgreements || []),
                {
                  price: agreementDetails.price,
                  startDate: agreementDetails.startDate,
                  paymentMethod: agreementDetails.paymentMethod,
                  agreement: agreementDetails.agreement,
                  updated: Timestamp.now(),
                },
              ]
            : agreementDetails.pastAgreements || [],
        },
        updated: Timestamp.now(),
        pin: Number(adminPin),
      }).then((resp) => {
        if (agreementDetailsChanged) {
          fetchShopSettings(selectedShop.id);
        }
        dispatch(doneSaving());

        console.log('settings saved :>> ', resp);
      });
    });
  };
};

export const startGettingDeviceTypes = () => {
  return {
    type: 'START_GETTING_DEVICETYPES',
  };
};

export const setDeviceTypes = (dTypes) => {
  return {
    type: 'SET_DEIVCE_TYPES',
    dTypes,
  };
};

export const getDeviceTypes = () => {
  return (dispatch) => {
    dispatch(startGettingDeviceTypes());
    const q = query(collection(fireStore, 'device-types'), orderBy('created', 'asc'));
    getDocs(q).then((querySnapshot) => {
      let deviceTypes = [];
      querySnapshot.forEach((d) => {
        deviceTypes.push({ ...d.data(), id: d.id });
      });
      dispatch(setDeviceTypes(deviceTypes));
    });
  };
};

export const addFunctionCheck = (id, check) => {
  return (dispatch) => {
    dispatch(startGettingDeviceTypes());
    const docRef = doc(fireStore, 'device-types', id);
    updateDoc(docRef, { functionChecks: arrayUnion(check) }).then((response) => {
      dispatch(getDeviceTypes());
      console.log('function check added :>> ', response);
    });
  };
};

export const deleteFunctionCheck = (id, check) => {
  return (dispatch) => {
    dispatch(startGettingDeviceTypes());
    const docRef = doc(fireStore, 'device-types', id);
    updateDoc(docRef, { functionChecks: arrayRemove(check) }).then((response) => {
      dispatch(getDeviceTypes());
      console.log('function check deleted :>> ', response);
    });
  };
};

export const startGettingBrands = () => {
  return {
    type: 'START_GETTING_BRANDS',
  };
};

export const setBrands = (brands) => {
  return {
    type: 'SET_BRANDS',
    brands,
  };
};

export const getBrands = () => {
  return (dispatch) => {
    dispatch(startGettingBrands());
    const q = query(collection(fireStore, 'brands'), orderBy('created', 'asc'));
    getDocs(q).then((querySnapshot) => {
      let brands = [];
      querySnapshot.forEach((d) => {
        brands.push({ ...d.data(), id: d.id });
      });
      dispatch(setBrands(brands));
    });
  };
};

export const addBrand = (brand, from) => {
  return (dispatch) => {
    if (from !== 'add device') {
      dispatch(setBackdropVisible(false, '', ''));
    }
    dispatch(startGettingBrands());
    addDoc(collection(fireStore, 'brands'), {
      created: Timestamp.now(),
      ...brand,
    }).then((response) => {
      dispatch(getBrands());
      console.log('brand added :>> ', response);
    });
  };
};

export const updateBrand = (id, attribute, value) => {
  return (dispatch) => {
    dispatch(startGettingBrands());
    const docRef = doc(fireStore, 'brands', id);
    updateDoc(docRef, { [attribute]: value }).then((response) => {
      dispatch(getBrands());
      console.log('brand updated :>> ', response);
    });
  };
};

export const addBrandDeviceType = (id, dTypeId) => {
  return (dispatch) => {
    dispatch(startGettingBrands());
    const docRef = doc(fireStore, 'brands', id);
    updateDoc(docRef, { deviceTypes: arrayUnion(dTypeId) }).then((response) => {
      dispatch(getBrands());
      console.log('Brand device type added :>> ', response);
    });
  };
};

export const deleteBrandDeviceType = (id, dTypeId) => {
  return (dispatch) => {
    dispatch(startGettingBrands());
    const docRef = doc(fireStore, 'brands', id);
    updateDoc(docRef, { deviceTypes: arrayRemove(dTypeId) }).then((response) => {
      dispatch(getBrands());
      console.log('Brand device type removed :>> ', response);
    });
  };
};

export const startGettingDevices = () => {
  return {
    type: 'START_GETTING_DEVICES',
  };
};

export const setDevices = (devices) => {
  return {
    type: 'SET_DEVICES',
    devices,
  };
};

export const getDevices = (fromTable, currDeviceType) => {
  return (dispatch) => {
    dispatch(startGettingDevices());
    const q = query(collection(fireStore, 'devices'), orderBy('created', 'desc'));
    getDocs(q).then((querySnapshot) => {
      let devices = [];
      querySnapshot.forEach((d) => {
        devices.push({ ...d.data(), id: d.id });
      });
      dispatch(setDevices(devices));
      if (!fromTable) {
        dispatch(setDeviceType(currDeviceType));
      }
    });
  };
};

export const addDevice = (device, closeBackdrop, currDeviceType) => {
  console.log('closeBackdrop', closeBackdrop);
  return (dispatch) => {
    if (closeBackdrop) {
      dispatch(setBackdropVisible(false, '', ''));
    }
    dispatch(startGettingDevices());
    addDoc(collection(fireStore, 'devices'), {
      created: Timestamp.now(),
      ...device,
    }).then((response) => {
      dispatch(getDevices(closeBackdrop, currDeviceType));
      console.log('device added :>> ', response);
    });
  };
};

export const updateDevice = (id, data) => {
  return (dispatch) => {
    dispatch(setBackdropVisible(false, '', ''));
    dispatch(startGettingDevices());
    const docRef = doc(fireStore, 'devices', id);
    updateDoc(docRef, { updated: Timestamp.now(), ...data }).then((response) => {
      dispatch(getDevices(true));
      console.log('device updated :>> ', response);
    });
  };
};

export const setSingleDevice = (id, service) => {
  return {
    type: 'SET_SINGLE_DEVICE',
    id,
    service,
  };
};

// export const getSingleDevice = (id) => {
//   return (dispatch) => {
//     const docRef = doc(fireStore, "devices", id);
//     getDoc(docRef).then((device) => {
//       dispatch(setSingleDevice(device.data()));
//     });
//   };
// };

export const addRepairServicesToDevice = (id, service) => {
  return (dispatch) => {
    console.log('id', id);
    console.log('service', service);
    dispatch(startGettingDevices());
    const docRef = doc(fireStore, 'devices', id);
    updateDoc(docRef, {
      updated: Timestamp.now(),
      repairServices: arrayUnion({ name: service.name, price: service.price }),
    }).then((response) => {
      console.log('repairService added :>> ', response);
      dispatch(setSingleDevice(id, service));
    });
  };
};

export const deleteDevice = (id) => {
  return (dispatch) => {
    dispatch(startGettingDevices());
    const docRef = doc(fireStore, 'devices', id);
    deleteDoc(docRef, id).then(() => {
      dispatch(getDevices(true));
      console.log('Device Deleted');
    });
  };
};

export const startGettingItems = () => {
  return {
    type: 'START_GETTING_ITEMSS',
  };
};

export const setItems = (items) => {
  return {
    type: 'SET_ITEMS',
    items,
  };
};

export const getItems = () => {
  return (dispatch) => {
    dispatch(startGettingItems());
    const q = query(collection(fireStore, 'items'), orderBy('created', 'desc'));
    getDocs(q).then((querySnapshot) => {
      let items = [];
      querySnapshot.forEach((d) => {
        items.push({ ...d.data(), id: d.id });
      });
      dispatch(setItems(items));
    });
  };
};

export const addItems = (items, fromTable) => {
  return (dispatch) => {
    if (fromTable) {
      dispatch(setBackdropVisible(false, '', ''));
    }
    dispatch(startGettingItems());
    items.forEach((i) => {
      addDoc(collection(fireStore, 'items'), {
        created: Timestamp.now(),
        ...i,
      }).then((response) => {
        console.log('item added :>> ', response);
      });
    });
    dispatch(getItems());
  };
};

export const updateItem = (id, attribute, value) => {
  return (dispatch) => {
    dispatch(startGettingItems());
    const docRef = doc(fireStore, 'items', id);
    updateDoc(docRef, { [attribute]: value, updated: Timestamp.now() }).then((response) => {
      dispatch(getItems());
      console.log('item updated :>> ', response);
    });
  };
};

export const deleteItem = (id) => {
  return (dispatch) => {
    dispatch(startGettingItems());
    const docRef = doc(fireStore, 'items', id);
    deleteDoc(docRef, id).then(() => {
      dispatch(getItems());
      console.log('Item Deleted');
    });
  };
};

export const startGettingTechnicians = () => {
  return {
    type: 'START_GETTING_TECHNICIANS',
  };
};

export const setTechnicians = (technicians) => {
  return {
    type: 'SET_TECHNICIANS',
    technicians,
  };
};

export const getTechnicians = () => {
  return (dispatch) => {
    dispatch(startGettingTechnicians());
    const q = query(collection(fireStore, 'technicians'), orderBy('created', 'desc'));
    getDocs(q).then((querySnapshot) => {
      let technicians = [];
      querySnapshot.forEach((d) => {
        technicians.push({ ...d.data(), id: d.id });
      });
      dispatch(setTechnicians(technicians));
    });
  };
};

export const addTechnician = (technician) => {
  return (dispatch) => {
    dispatch(startGettingTechnicians());

    addDoc(collection(fireStore, 'technicians'), {
      created: Timestamp.now(),
      ...technician,
    }).then((response) => {
      console.log('Technician added :>> ', response);
      dispatch(getTechnicians());
    });
  };
};

export const updateTechnician = (id, attribute, value) => {
  return (dispatch) => {
    dispatch(startGettingTechnicians());
    const docRef = doc(fireStore, 'technicians', id);
    updateDoc(docRef, { [attribute]: value, updated: Timestamp.now() }).then((response) => {
      dispatch(getTechnicians());
      console.log('Technician updated :>> ', response);
    });
  };
};

export const deleteTechnician = (id) => {
  return (dispatch) => {
    dispatch(startGettingTechnicians());
    const docRef = doc(fireStore, 'technicians', id);
    deleteDoc(docRef, id).then(() => {
      dispatch(getTechnicians());
      console.log('Technician Deleted');
    });
  };
};

export const startLoadDatabase = () => {
  return {
    type: 'START_LOAD_DATABASE',
  };
};

export const databaseLoadComplete = () => {
  return {
    type: 'DATABASE_LOAD_COMPLETE',
  };
};

export const startGettingCustomers = () => {
  return {
    type: 'START_GETTING_CUSTOMERS',
  };
};

export const setCustomers = (customers) => {
  return {
    type: 'SET_CUSTOMERS',
    customers,
  };
};

export const getCustomers = () => {
  return (dispatch) => {
    dispatch(startGettingCustomers());
    // const q = query(collection(fireStore, 'customers'), orderBy('created', 'asc'));
    // getDocs(q).then((querySnapshot) => {
    //   let customers = [];
    //   querySnapshot.forEach((d) => {
    //     customers.push({ ...d.data(), id: d.id });
    //   });
    //   dispatch(setCustomers(customers));
    // });
  };
};

export const getCustomersByQuery = (queryText) => {
  return (dispatch) => {
    dispatch(startGettingCustomers());
    const q = query(
      collection(fireStore, 'customers'),
      where('name', '>=', queryText),
      where('name', '<=', queryText + '\uf8ff'),
      orderBy('name', 'asc')
    );
    getDocs(q).then((querySnapshot) => {
      let customers = [];
      querySnapshot.forEach((d) => {
        console.log('d :>> ', d);
        customers.push({ ...d.data(), id: d.id });
      });
      dispatch(setCustomers(customers));
    });
  };
};

export const searchCustomersByFilter = (filters) => {
  return (dispatch) => {
    dispatch(startGettingCustomers());
    const q = query(
      collection(fireStore, 'customers'),
      filters.name !== '' ? where('name', '==', filters.name) : limit(500),
      filters.phone !== '' ? where('phone', '==', filters.phone) : limit(500),
      filters.street !== '' ? where('street', '==', filters.street) : limit(500),
      filters.city !== '' ? where('city', '==', filters.city) : limit(500),
      filters.postalCode !== '' ? where('postalCode', '==', filters.postalCode) : limit(500),
      limit(500)
    );
    getDocs(q).then((querySnapshot) => {
      let customers = [];
      querySnapshot.forEach((d) => {
        console.log('d :>> ', d);
        customers.push({ ...d.data(), id: d.id });
      });
      dispatch(setCustomers(customers));
    });
  };
};

export const updateCustomer = (id, attribute, value) => {
  return (dispatch) => {
    dispatch(startGettingCustomers());
    const docRef = doc(fireStore, 'customers', id);
    updateDoc(docRef, { [attribute]: value, updated: Timestamp.now() }).then((response) => {
      // dispatch(getCustomers());
      console.log('Customer updated :>> ', response);
    });
  };
};

export const loadFromDatabase = () => {
  return (dispatch) => {
    // dispatch(startLoadDatabase());
    // dispatch(getSettings('y1ap63Thh2ywGAxjvexM'));
    // dispatch(getDeviceTypes());
    // dispatch(getBrands());
    // dispatch(getDevices(true));
    // dispatch(getItems());
    // dispatch(getTechnicians());
  };
};
