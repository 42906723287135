import { onAuthStateChanged, getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { fireStore } from '../../firebase/config';
import { doc, getDoc } from 'firebase/firestore';
// import { auth } from "../../firebase/config";

export const loginStart = () => {
  return { type: 'LOGIN_START' };
};
export const loginSuccess = (user) => {
  return { type: 'LOGIN_SUCCESS', user };
};
export const loginFailed = (err) => {
  return { type: 'LOGIN_FAILED', err };
};

export const login = (email, password) => {
  const auth = getAuth();
  return (dispatch) => {
    dispatch(loginStart());
    signInWithEmailAndPassword(auth, email, password)
      .then(({ user }) => {
        dispatch(verifyAuthorization(user));
      })
      .catch((err) => dispatch(loginFailed(err)));
  };
};

export const verifyAuthorization = (user) => {
  return (dispatch) => {
    dispatch(verifyStart());
    // get user from users collection where user id is equal to user.uid
    const userRef = doc(fireStore, 'users', user.uid);
    getDoc(userRef).then((doc) => {
      if (doc.data()) {
        const { role, permission } = doc.data();
        console.log('doc.data() :>> ', doc.data());
        if (role === 'superAdmin' && permission === 'all') {
          dispatch(verifySuccess());
          dispatch(loginSuccess(user));
          dispatch(setCurrentUser(user));
        } else {
          dispatch(verifyFailed());
          dispatch(loginFailed('You are not authorized to access this page'));
        }
      } else {
        dispatch(verifyFailed());
      }
    });
  };
};

export const setCurrentUser = (user) => {
  return {
    type: 'SET_CURRENT_USER',
    user,
  };
};

export const verifyStart = () => {
  return { type: 'VERIFY_START' };
};

export const verifySuccess = () => {
  return { type: 'VERIFY_SUCCESS' };
};

export const verifyFailed = () => {
  return { type: 'VERIFY_FAILED' };
};

export const verifyAuth = () => {
  const auth = getAuth();

  return (dispatch) => {
    dispatch(verifyStart());
    onAuthStateChanged(auth, (user) => {
      if (user) {
        dispatch(verifyAuthorization(user));
      } else {
        dispatch(verifyFailed());
        dispatch(setCurrentUser(null));
        // dispatch(signOutUser());
      }
    });
  };
};
