import * as React from "react";
import { useDispatch, useSelector } from "react-redux";

import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Button, CircularProgress, Paper, TextField } from "@mui/material";

import { DummyInvoice } from "../../components/Printings/DummyInvoice";
import { saveSettings, setEstimationInvoice, setGeneralInvoice } from "../../Redux/Actions";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { DummyEstimationInvoice } from "../Printings/DummyEstimationInvoice";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const formStyle = {
  textAlign: "left",
  display: "flex",
  flexDirection: "column",
  width: "80%",
  margin: "20px",
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
    sx: { fontWeight: "bold", fontSize: "1rem" },
  };
}

export default function InvoiceSettings() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [value, setValue] = useState(0);
  const allSettings = useSelector((state) => state.company);
  const generalInvoice = allSettings.generalInvoice;
  const estimationInvoice = allSettings.estimationInvoice;
  const settingsLoading = allSettings.settingsLoading;

  const [changed, setChanged] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleInputs = (attr, value) => {
    dispatch(setGeneralInvoice(attr, value));
    setChanged(true);
  };

  const handleEstimationInputs = (attr, value) => {
    dispatch(setEstimationInvoice(attr, value));
    setChanged(true);
  };

  const handleSaveSettings = () => {
    const settings = {
      settingsId: allSettings.settingsId,
      companyDetails: allSettings.companyDetails,
      generalInvoice: allSettings.generalInvoice,
      estimationInvoice: allSettings.estimationInvoice,
    };
    dispatch(saveSettings(settings));
    setChanged(false);
  };

  return (
    <Paper sx={{ width: "100%", background: "white", padding: "20px" }}>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs">
          <Tab label={t("General")} {...a11yProps(0)} />
          <Tab label={t("Estimation")} {...a11yProps(1)} />
        </Tabs>
        <div style={{ minWidth: "100px" }}>
          {settingsLoading ? (
            <CircularProgress />
          ) : (
            <Button
              variant="contained"
              size="large"
              disabled={!changed}
              onClick={() => handleSaveSettings()}
            >
              {t("Save")}
            </Button>
          )}
        </div>
      </Box>
      <div style={{ display: "flex", marginTop: "20px" }}>
        <div style={{ width: "50%" }}>
          {value === 0 && (
            <div style={formStyle}>
              <TextField
                label={t("Heading")}
                value={generalInvoice.heading}
                onChange={(e) => handleInputs("heading", e.target.value)}
                margin="normal"
              />
              <TextField
                label={t("Introduction Text")}
                value={generalInvoice.introText}
                multiline
                rows={3}
                onChange={(e) => handleInputs("introText", e.target.value)}
                margin="normal"
              />

              <TextField
                label={t("Terms & Conditions")}
                multiline
                rows={10}
                value={generalInvoice.termsConditions}
                onChange={(e) => handleInputs("termsConditions", e.target.value)}
                margin="normal"
              />
            </div>
          )}

          {value === 1 && (
            <div style={formStyle}>
              <TextField
                label={t("Heading")}
                value={estimationInvoice.heading}
                onChange={(e) => handleEstimationInputs("heading", e.target.value)}
                margin="normal"
              />
              <TextField
                label={t("Introduction Text")}
                value={estimationInvoice.introText}
                multiline
                rows={3}
                onChange={(e) => handleEstimationInputs("introText", e.target.value)}
                margin="normal"
              />

              <TextField
                label={t("Terms & Conditions")}
                multiline
                rows={10}
                value={estimationInvoice.termsConditions}
                onChange={(e) => handleEstimationInputs("termsConditions", e.target.value)}
                margin="normal"
              />
            </div>
          )}
        </div>
        {value === 0 && <DummyInvoice />}
        {value === 1 && <DummyEstimationInvoice />}
      </div>
    </Paper>
  );
}
