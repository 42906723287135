import React from 'react';

import Barcode from 'react-barcode';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import './Invoice.css';

export const Invoice = React.forwardRef((props, ref) => {
  const { t } = useTranslation();
  const currentJob = useSelector((state) => state.job.currentJob);
  const device = currentJob.device;
  const customer = currentJob.customer;
  const totals = currentJob.totals;
  const services = currentJob.jobServices;
  const items = currentJob.extraItems;
  const currentAction = useSelector((state) => state.job.currentAction);

  const company = useSelector((state) => state.company.companyDetails);
  const invoice = useSelector((state) => state.company.generalInvoice);

  const priceFormat = window.$priceFormater;

  // console.log('barcode', barcode)
  const dateTimeOptions = {
    year: '2-digit',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
    timeZone: 'Europe/Berlin',
  };
  return (
    <div style={{ margin: 'auto', textAlign: 'left' }} ref={ref} className="invoice-box">
      <div style={{ margin: 'auto', width: '90%', fontSize: '12px' }}>
        {/* LOGO  */}
        <div
          style={{
            textAlign: 'right',
            height: '80px',
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          {company.logoUrl === '' ? (
            <div className="dummy-logo">LOGO</div>
          ) : (
            <img
              src={company.logoUrl}
              alt="company-logo"
              height={80}
              style={{ maxWidth: '200px' }}
            />
          )}
        </div>

        {/* HEADER  */}
        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '2px' }}>
          <div>
            <ul style={{ listStyle: 'none', textAlign: 'left', paddingInlineStart: 'unset' }}>
              <li style={{ textDecoration: 'underline' }}>
                <b>{t('Client')}</b>
              </li>
              <li>{customer.differentNameOnInvoice ? customer.invoiceOf.name : customer.name}</li>
              {customer.companyRepresentative !== '' ? (
                <li> {customer.companyRepresentative}</li>
              ) : null}
              <li>
                {customer.differentNameOnInvoice ? customer.invoiceOf.street : customer.street}
                &nbsp;
                {customer.differentNameOnInvoice ? customer.invoiceOf.houseNo : customer.houseNo}
              </li>
              <li>
                {customer.differentNameOnInvoice
                  ? customer.invoiceOf.postalCode
                  : customer.postalCode}
                ,{customer.differentNameOnInvoice ? customer.invoiceOf.city : customer.city}
              </li>
              <li>{customer.differentNameOnInvoice ? customer.invoiceOf.phone : customer.phone}</li>
              {customer.differentNameOnInvoice ? (
                <>
                  <li>
                    <b>{t('Contact')}:</b>
                  </li>
                  <li>{customer.name}</li>
                  <li>{customer.phone}</li>
                </>
              ) : null}
            </ul>
          </div>
          <div>
            <ul style={{ listStyle: 'none', textAlign: 'right', paddingInlineStart: 'unset' }}>
              <li>{company.name}</li>
              <li>
                {company.street}&nbsp;{company.houseNo}
              </li>
              <li>
                {company.postalCode}, {company.city}
              </li>
              <li>{company.phone}</li>
            </ul>
          </div>
        </div>

        {/* BODY  */}
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ textAlign: 'left', width: '70%', paddingTop: '20px', marginRight: '20px' }}>
            <div style={{ display: 'flex' }}>
              <div>
                <div>
                  <b>{t('Device Data')}:</b>
                </div>
                <br />
                {device.memory === '' ? null : (
                  <div>
                    <b>{t('Memory')}:</b>
                  </div>
                )}
                {device.accessories.length === 0 ? null : (
                  <div>
                    <b>{t('Accessories')}:</b>
                  </div>
                )}
                {device.defects.length === 0 ? null : (
                  <div>
                    <b>{t('Fault Description')}:</b>
                  </div>
                )}
                {device.notes === '' ? null : (
                  <div>
                    <b>{t('Notes')}:</b>
                  </div>
                )}
              </div>
              <div style={{ padding: '0 10px 0 10px' }}>
                <div>
                  {device.name}
                  <br />
                  {t('Serial')}: {device.serial}
                </div>
                {device.memory === '' ? null : <div>{device.memory}</div>}
                {device.accessories.length === 0 ? null : (
                  <div>
                    {device.accessories.map(
                      (a, i) => a + (i < device.accessories.length - 1 ? ' | ' : '')
                    )}
                  </div>
                )}
                {device.defects.length === 0 ? null : (
                  <div>
                    {device.defects.map((d, i) => d + (i < device.defects.length - 1 ? ' | ' : ''))}
                  </div>
                )}
                {device.notes === '' ? null : <div>{device.notes}</div>}
              </div>
            </div>
            <h3 style={{ textDecoration: 'underline' }}>{invoice.heading}</h3>
            <p style={{ whiteSpace: 'pre-line' }}>{invoice.introText}</p>
          </div>
          <div>
            <ul style={{ listStyle: 'none', textAlign: 'right', paddingInlineStart: 'unset' }}>
              <li>
                <Barcode
                  value={currentJob.jobNumber}
                  height={25}
                  fontSize={10}
                  width={1.5}
                  margin={5}
                />
              </li>
              <li>
                <Barcode
                  value={new Intl.NumberFormat('de-DE', {
                    currency: 'EUR',
                    minimumFractionDigits: 2,
                  }).format(totals.total)}
                  height={25}
                  fontSize={10}
                  width={1.5}
                  margin={5}
                />
              </li>
              <li>
                <b>{t('Date')}:</b>
                {currentAction === 'adding'
                  ? new Date().toLocaleDateString('de-DE', dateTimeOptions)
                  : new Date(currentJob.timestamp.seconds * 1000).toLocaleDateString(
                      'de-DE',
                      dateTimeOptions
                    )}
              </li>
              <li>
                <b>{t('Return Date')}:</b>{' '}
                {currentJob.returnDate
                  ? currentJob.returnDate instanceof Date
                    ? new Date(currentJob.returnDate).toLocaleDateString('de-DE', dateTimeOptions)
                    : new Date(currentJob.returnDate.seconds * 1000).toLocaleDateString(
                        'de-DE',
                        dateTimeOptions
                      )
                  : new Date(currentJob.returnDate).toLocaleDateString('de-DE', dateTimeOptions)}
              </li>
            </ul>
          </div>
        </div>

        {/* PRICE TABLE  */}
        <table style={{ width: '100%', marginTop: '5px', lineHeight: 1 }}>
          <tbody>
            <tr>
              <th style={{ textAlign: 'left', width: '50%', borderBottom: '1px solid black' }}>
                Beschreibung
              </th>
              <th style={{ textAlign: 'right', borderBottom: '1px solid black' }}>Menge</th>
              <th style={{ textAlign: 'right', borderBottom: '1px solid black' }}>Preis</th>
              {/* <th style={{ textAlign: "right", borderBottom: "1px solid black" }}>MwSt.</th> */}
              <th style={{ textAlign: 'right', borderBottom: '1px solid black' }}>Gesamt</th>
            </tr>
            <tr style={{ fontWeight: 'bold' }}>
              <td>{t('Services')}:</td>
            </tr>
            {services.map((s, i) => (
              <tr key={i}>
                <td style={{ textAlign: 'left' }}>{s.name}</td>
                <td style={{ textAlign: 'right' }}>{s.quantity}</td>
                <td style={{ textAlign: 'right' }}>{s.price}</td>
                {/* <td style={{ textAlign: "right" }}>20</td> */}
                <td style={{ textAlign: 'right' }}>{s.price}</td>
              </tr>
            ))}
            {items.length === 0 ? null : (
              <>
                <tr style={{ fontWeight: 'bold' }}>
                  <td>{t('Items')}:</td>
                </tr>
                {items.map((t, i) => (
                  <tr key={i}>
                    <td style={{ textAlign: 'left' }}>{t.name}</td>
                    <td style={{ textAlign: 'right' }}>{t.quantity}</td>
                    <td style={{ textAlign: 'right' }}>{t.price}</td>
                    {/* <td style={{ textAlign: "right" }}>20</td> */}
                    <td style={{ textAlign: 'right' }}>{t.price * t.quantity}</td>
                  </tr>
                ))}
              </>
            )}
            <tr>
              <td>&nbsp;</td>
            </tr>
            {totals.discount !== 0 ? (
              <tr>
                <td></td>
                <td style={{ textAlign: 'left' }}>{t('Discount')}</td>
                <td colSpan={4} style={{ textAlign: 'right' }}>
                  -{priceFormat.format(Math.abs(totals.discount).toFixed(2))}
                </td>
              </tr>
            ) : null}
            <tr>
              <td></td>
              <td style={{ textAlign: 'left' }}>{t('Without Tax')}</td>
              <td colSpan={4} style={{ textAlign: 'right' }}>
                {priceFormat.format(totals.total / (1 + company.taxPercent / 100))}
              </td>
            </tr>
            <tr>
              <td></td>
              <td style={{ textAlign: 'left' }}>
                {company.taxTitle}&nbsp;{company.taxPercent}%
              </td>
              <td colSpan={4} style={{ textAlign: 'right' }}>
                {priceFormat.format(totals.total - totals.total / (1 + company.taxPercent / 100))}
              </td>
            </tr>

            <tr style={{ fontWeight: 'bold' }}>
              <td></td>
              <td style={{ textAlign: 'left' }}>{t('Total')}</td>
              <td colSpan={4} style={{ textAlign: 'right' }}>
                {priceFormat.format(totals.total)}
              </td>
            </tr>
          </tbody>
        </table>
        <div className="terms-block">
          <div>
            <p style={{ lineHeight: '9px', whiteSpace: 'pre-line', fontSize: '9px' }}>
              {invoice.termsConditions}
            </p>
          </div>
          <div
            style={{
              textAlign: 'right',
              marginTop: customer.signatures ? '5px' : '30px',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <div
              style={{ display: 'flex', alignItems: customer.signatures ? 'center' : 'flex-start' }}
            >
              <div style={{ fontSize: '12px', textAlign: 'left' }}>
                {`Mit meiner Unterschrift bestätige ich den Auftrag und stimme den AGB der Firma
                ${company.name} zu`}
                .&nbsp;
              </div>
              <div
                style={{
                  marginTop: customer.signatures ? '5px' : '20px',
                  marginLeft: customer.signatures ? '0' : '30px',
                  marginBottom: '15px',
                }}
              >
                {customer.signatures && (
                  <div>
                    <img src={customer.signatures} alt="signature" style={{ width: '100px' }} />
                  </div>
                )}
                <div>_______________________</div>
                <div style={{ marginBottom: '5px' }}>{t('Date, Signature')}</div>
              </div>
            </div>
          </div>
        </div>

        <div className="divFooter">
          <table style={{ width: '95%', fontSize: '12px' }}>
            <tbody>
              <tr style={{ lineHeight: '12px' }}>
                <td style={{ verticalAlign: 'baseline' }}>
                  {company.name}
                  <br />
                  {company.street}&nbsp;{company.houseNo}
                  <br />
                  {company.postalCode}, {company.city}
                  <br />
                  Tel: {company.phone}
                  <br />
                  Email: {company.email}
                  <br />
                  Webseite: {company.website}
                </td>
                <td style={{ textAlign: 'left', verticalAlign: 'baseline' }}>
                  UID: {company.uid}
                  <br />
                  Registernummer: {company.regNo}
                  <br />
                  {company.city}
                  <br />
                  {company.ceo}
                  <br />
                </td>
                <td style={{ verticalAlign: 'baseline' }}>
                  {company.name}
                  <br />
                  {company.bank}
                  <br />
                  IBAN: {company.iban}
                  <br />
                  BIC: {company.bic}
                  <br />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
});
