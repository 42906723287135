import React from "react";
import CompanyProfile from "../../components/Profile/CompanyProfile";

function Company() {
  return (
    <div>
      <CompanyProfile />
    </div>
  );
}

export default Company;
